import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Web3 = makeShortcode("Web3");
const Video = makeShortcode("Video");
const Process = makeShortcode("Process");
const Box = makeShortcode("Box");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const InfoBlock = makeShortcode("InfoBlock");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-the-art-of-conversation",
      "style": {
        "position": "relative"
      }
    }}>{`💬 The Art of Conversation`}<a parentName="h1" {...{
        "href": "#-the-art-of-conversation",
        "aria-label": " the art of conversation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <Web3 mdxType="Web3" />
    <Video src="https://www.youtube-nocookie.com/embed/CCYw0QVoB_U" mdxType="Video" />
    <p>{`This documentary features Paul Myburgh, author of `}<em parentName="p">{`The Bushmen Winter Has Come`}</em>{`, who spent seven years living with the !Gwi Kalahari Bushmen, exploring ancient conversation and being.`}</p>
    <h2 {...{
      "id": "how-does-this-fit-into-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`How does this fit into Kernel?`}<a parentName="h2" {...{
        "href": "#how-does-this-fit-into-kernel",
        "aria-label": "how does this fit into kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`There are many important insights here for the conversations we cultivate in Kernel and carry out into the wider world. We select here some of the key quotes, questions and teachings Paul offers as a way into understanding the brief to follow.`}</p>
    <Process mdxType="Process">
      <p><strong parentName="p">{`"The quality of listening determines the quality of speaking."`}</strong></p>
      <p>{`"When people are listening, I'm compelled to speak more truth."`}</p>
      <p>{`We should aspire to positive sum, horizontal conversations (9:14 in the video).`}</p>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Horizontal conversations:`}</strong>{` interactive dialogue among peers. Participants share trust, living within the same context. Conversations continue and can be picked up tomorrow, or next week, or sometime later. Much more meaning is shared.`}</p>
        <blockquote>
          <p parentName="blockquote">{`"I have a lot of respect for people who think as they speak, and who pause to think and to attach `}<strong parentName="p">{`the meaning that matters`}</strong>{`."`}</p>
        </blockquote>
        <p><strong parentName="p">{`Vertical conversations:`}</strong>{` Each person speaks to get their point in — not building upon each other. It becomes a competition because you are thinking of what to say instead of listening. It doesn't allow one to hear, or to speak truthfully. Less meaning is shared.`}</p>
      </Box>
      <p>{`Asking better questions by listening more deeply`}</p>
      <Box mdxType="Box">
        <p>{`What kinds of `}<strong parentName="p">{`speech`}</strong>{` really carry `}<strong parentName="p">{`value`}</strong>{`?`}</p>
      </Box>
    </Process>
    <h2 {...{
      "id": "brief",
      "style": {
        "position": "relative"
      }
    }}>{`Brief`}<a parentName="h2" {...{
        "href": "#brief",
        "aria-label": "brief permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <blockquote>
      <p parentName="blockquote">{`"We live in a world where it's become the fashion to just drop these cute one-liners at each other and think then that we know things. We ask questions without really wanting the answer - all we're seeking is a quick-fix; a conceptual solution to a curiosity that doesn't really matter because it's just a passing fancy. We don't live in a world where we have conversations about things that really matter! I mean endless explorations of the truth outside of ourselves..."`}</p>
    </blockquote>
    <p>{`What is the truth outside yourself? How could you build open tools that encourage people to explore such ideas and connect in truly meaningful ways?`}</p>
    <blockquote>
      <p parentName="blockquote">{`"We have a thousand alternatives for every fundamental, and we spend much of our lives preoccupied with those alternatives. Our lives therefore tend to be far more `}<strong parentName="p">{`incidentally bound,`}</strong>{` rather than `}<strong parentName="p">{`fundamentally related`}</strong>{`. In this we express our separation: from the natural world, from the fundamentals, from the cosmos."`}</p>
    </blockquote>
    <p>{`This is a profound linguistic play: we all find ourselves caught up in seemingly insignificant tasks which consistently get in the way of that really profound thing you've been meaning to learn or do. Why is this? How can we move more of our daily existence into that which is not bound and imprisoned in trivialities, but rather fundamentally related to everything around us? Isn't that what a web does?`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What kinds of conversations do we build together in Kernel?`}</p>
        <p>{`Horizontal`}</p>
      </Card>
    </Flash>
    <blockquote>
      <p parentName="blockquote">{`"I missed `}<strong parentName="p">{`True Voice`}</strong>{`".`}</p>
    </blockquote>
    <p>{`What is True Voice? Have you ever heard it?`}</p>
    <blockquote>
      <p parentName="blockquote">{`"People listen. There are pauses between speaking which are filled with thinking, and with understanding."`}</p>
    </blockquote>
    <p>{`How comfortable are you with silence? Is this actually not the measure of companionship - how comfortable the silences are?`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Modern conversation doesn't give space for the words to settle in the listener `}{`[`}{`...`}{`]`}{` I have a lot of respect for people who think as they speak, and who pause to think and to attach `}<strong parentName="p">{`the meaning that matters`}</strong>{`."`}</p>
    </blockquote>
    <h3 {...{
      "id": "the-thirst-for-truth",
      "style": {
        "position": "relative"
      }
    }}>{`The Thirst For Truth`}<a parentName="h3" {...{
        "href": "#the-thirst-for-truth",
        "aria-label": "the thirst for truth permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <InfoBlock mdxType="InfoBlock">
      <p>{`"To do what is just with all one's soul, and to tell the truth. What remains for you to do but enjoy life, linking each good thing to the next, without leaving the slightest interval between them." – Pierre Hadot`}</p>
    </InfoBlock>
    <p>{`How can you cultivate the space within yourself required to listen well? What does it really mean to attach conscious meaning to the words you say or write, linking each good thing to the next? How is this amplified when those words are executable code?`}</p>
    <blockquote>
      <p parentName="blockquote">{`"One's speaking must be shaped by the quality of the listener. So, when you're listening to me I am compelled to speak more sense. I am compelled to speak more truth. In a lot of modern conversations, no-one is listening, so no-one cares what they say `}{`[...]`}{` The quality of listening informs the quality of speaking."`}</p>
    </blockquote>
    <p>{`How can we build tools and digital spaces which encourage high-quality listening, rather than just meaningless self-promotion?`}</p>
    <blockquote>
      <p parentName="blockquote">{`"You go beyond thirst and you understand that there is no such thing as thirst. You either have water, or you do not have water. And in between is nothing."`}</p>
    </blockquote>
    <p>{`What are the fictional longings in your life? How could you get to their opposite to see how hollow fear and longing really are?`}</p>
    <h3 {...{
      "id": "old-gifts-anew",
      "style": {
        "position": "relative"
      }
    }}>{`Old Gifts Anew`}<a parentName="h3" {...{
        "href": "#old-gifts-anew",
        "aria-label": "old gifts anew permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"Modern humanity does not learn by being gifted. Our path is different. We're constituted to ask questions. For us, knowing is something that is achieved through effort, out of free will. The task of the modern human being is to clearly and definitively seek and find knowledge. Not cheap, conceptual information, but the knowledge of things themselves. I mean `}<strong parentName="p">{`perceptual knowledge.`}</strong>{` Not quick ideas, but the actual knowledge of things. No free gifts, no quick fixes. Hard yards. Every human soul taking every single step."`}</p>
    </blockquote>
    <p>{`Can you think back to moments in your life when you "just knew" something? What was the quality and texture of that knowing? How can we cultivate more of that in our lives, and how can we build products which help "every human soul take `}{`[even a]`}{` single step" closer to their own truths?`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What is required to co-create horizontal conversations?`}</p>
        <p>{`Clear listening.`}</p>
      </Card>
    </Flash>
    <blockquote>
      <p parentName="blockquote">{`"More and more I see the value of human conversation: the value of what arises between us when we speak in truth with what we have and what we seek. All of us. When I see this human conversation that `}<strong parentName="p">{`must`}</strong>{` arise again, there is something more that is asked here. Which is that human beings begin to converse for ourselves, and with ourselves, so that we can - once again - `}<strong parentName="p">{`find what was old community in future form`}</strong>{`. And that will only grow out of a conversation about that which we know, and that which we wish to know."`}</p>
    </blockquote>
    <p>{`This notion of "that which we wish to know" leads us to a `}<a parentName="p" {...{
        "href": "https://web.archive.org/web/20230202072611/https://publicism.info/science/machines/9.html"
      }}>{`surprising story`}</a>{` about another complementary pair of opposites: artificial intelligence and intelligence augmentation. It is a story which reveals how the future of search is now understood to be conversation: an ongoing dialogue about the tension between automating and augmenting the human experience. What is most fascinating is that`}</p>
    <blockquote>
      <p parentName="blockquote">{`Human-machine interaction is a form of conversation, a dynamical process, in which the participants learn about each other.`}</p>
    </blockquote>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Dialogue`}</strong></p>
        <p><img parentName="p" {...{
            "src": "/images/mod0-img/david_bohm_and_jiddu_krishnamurti.jpg",
            "alt": "David Bohm"
          }}></img></p>
        <p>{`David Bohm was one of the most significant theoretical physicists of the 20th Century. He was also a close friend of Jiddu Krishnamurti.`}</p>
        <p>{`"With this common coherent consciousness we have a new kind of intelligence capable of thinking together. We have to begin with people who are open enough to start the dialogue."`}</p>
        <Aligner center mdxType="Aligner">
          <Button to="https://www.bohmdialogue.org/#bohmdialogue" mdxType="Button">Participate</Button>
        </Aligner>
      </Box>
    </InfoBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      